/**
 * Axios
 */
import axios from "axios";

/**
 * @profile
 */

export const login = (schema) => {
    // console.log(schema.email);
    const data = {
        password: schema.password,
    };
    if (schema.userType == "Admin") {
        data.username = schema.username;
        return new Promise((resolve, reject) => {
            axios.post("/admin/login", data).then(
                (res) => {
                    const token = res.data.token;
                    localStorage.setItem("admin-token", token);
                    resolve(res.data);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    } else {
        data.email = schema.username;
        return new Promise((resolve, reject) => {
            axios.post("/user/login", data).then(
                (res) => {
                    const token = res.data.token;
                    localStorage.setItem("user-token", token);
                    resolve(res.data);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }
};

export const logout = () => {
    localStorage.setItem("admin-token", "");
    localStorage.setItem("user-token", "");
    location.reload();
};